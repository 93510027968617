import { Tooltip } from "@mui/material";
import { classNames } from "core";
import { delay } from "lodash";
import React, { MouseEvent, useState } from "react";
import { ResizableBox } from "react-resizable";
import { MIN_SIDEBAR_WIDTH, MAX_SIDEBAR_WIDTH } from "source/constants";
import { useAppDispatch } from "source/redux";
import { setUserToggledSidebar, setSidebarVisible } from "source/redux/sidebar";
import { ResizeCallbackData } from "source/types/reactResizable.types";

export type DraggableSidebarProps = {
  open?: boolean;
  width?: number;
  windowWidth?: number;
  children: React.ReactNode;
  collapsible?: boolean;
  styleOverrides?: {
    root?: string;
    draggableBorder?: string;
  };
  setWidth?: (width: number) => void;
};

/** A wrapper around sidebars that need to be dragged. This wrapper prevents re-renders in the children components when this react-resizable is dragged around */
export const DraggableSidebar = ({
  open = true,
  children,
  width = MIN_SIDEBAR_WIDTH,
  windowWidth = window.innerWidth,
  collapsible = true,
  styleOverrides,
  setWidth,
}: DraggableSidebarProps) => {
  const dispatch = useAppDispatch();
  const [ignoreClick, setIgnoreClick] = useState(false);

  if (!open) return null;

  const maxSidebarWidth = windowWidth ? windowWidth * 0.5 : MAX_SIDEBAR_WIDTH;

  const onResize = (_e: MouseEvent, d: ResizeCallbackData) => {
    // prevent the user from selecting text on the screen while dragging
    document.body.style.userSelect = "none";
    // Flag that we are dragging the sidebar and shouldn't close the sidebar on mouseup
    // This has to be set here since onResizeStart always fires on click
    setIgnoreClick(true);

    if (setWidth) {
      setWidth(d.size.width);
    }
  };

  const onResizeStop = () => {
    // Super janky but this lets us ignore the mouseUp event AFTER drag end so the sidebar doesn't also close
    delay(() => {
      setIgnoreClick(false);
    }, 50);

    // re-enable the ability to select text
    document.body.style.userSelect = "";
  };

  return (
    <div
      className={classNames(
        "sticky top-0 z-20 h-screen overflow-x-hidden overscroll-none bg-backgroundLight text-secondary",
        styleOverrides?.root
      )}
    >
      <ResizableBox
        className={"h-full"}
        width={open ? width : 0}
        minConstraints={[MIN_SIDEBAR_WIDTH, 0]}
        maxConstraints={[maxSidebarWidth, Infinity]}
        resizeHandles={["e"]}
        axis="x"
        handle={
          open && (
            <Tooltip
              followCursor={true}
              title={
                <>
                  <b>Drag</b> to resize
                  {!!collapsible && (
                    <>
                      <br />
                      <b>Click</b> to collapse
                    </>
                  )}
                </>
              }
            >
              <div
                className="group absolute -right-2 top-0 z-50 flex h-full w-4 cursor-col-resize justify-center"
                onClick={() => {
                  // Don't close the sidebar if it's not collapsible or if the event came after a resize
                  if (!collapsible || ignoreClick) {
                    return;
                  }

                  dispatch(setSidebarVisible(false));
                  dispatch(setUserToggledSidebar(true));
                }}
              >
                <div
                  className={classNames(
                    "m-auto h-full w-px bg-gray-200 group-hover:bg-gray-300 group-active:bg-gray-300",
                    styleOverrides?.draggableBorder
                  )}
                />
                <div className="bg-text-secondary hidden h-full w-0.5 opacity-0 transition-all duration-100 ease-linear group-hover:visible group-hover:opacity-100" />
              </div>
            </Tooltip>
          )
        }
        onResize={onResize}
        onResizeStop={onResizeStop}
      >
        {children}
      </ResizableBox>
    </div>
  );
};
