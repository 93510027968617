import React from "react";
import { StatusBanner } from "./StatusBanner";
import { CollapsedStatusBanner } from "./CollapsedStatusBanner";
import { useSelector } from "react-redux";
import {
  getStatusBannerCollapsed,
  setStatusBannerCollapsed,
} from "source/redux/banners";
import { useDispatch } from "react-redux";
import { useFeatureFlag } from "source/hooks/common/useFeatureFlag";
import { useGetRouter } from "source/hooks/useGetRouter";
import { getCurrentPage } from "source/utils/helpers";
import { isEmpty } from "lodash";
import { Alert } from "source/components/library/Alert/Alert";
import { getBannerHeader, getBannerMessage } from "./helpers";
import { Button } from "core";
import { MaterialSymbolsIcon } from "core";

export const FlaggedBanner = () => {
  const dispatch = useDispatch();
  const collapsed = useSelector(getStatusBannerCollapsed);
  const { router } = useGetRouter();
  const currentPage = getCurrentPage(router.pathname);

  const bannerSearchDown = useFeatureFlag("bannerSearchDown");
  const bannerMatrixDown = useFeatureFlag("bannerMatrixDown");
  const bannerChatDown = useFeatureFlag("bannerChatDown");
  const bannerBuildSlow = useFeatureFlag("bannerBuildSlow");
  const showBanner = useFeatureFlag("showBanner") ?? {};

  const showCustomBanner =
    !isEmpty(showBanner) && showBanner.pages?.includes(currentPage);

  const displayBanner =
    (bannerSearchDown && currentPage === "Search") ||
    (bannerMatrixDown && currentPage === "Matrix") ||
    (bannerChatDown &&
      (currentPage === "Chat" || currentPage === "ChatDocs")) ||
    showCustomBanner;

  const toggleCollapsed = () => {
    dispatch(setStatusBannerCollapsed(!collapsed));
  };

  if (!displayBanner) {
    return null;
  }

  if (collapsed) {
    return (
      <div className="pointer-events-none fixed bottom-4 right-0 z-[1000] mt-4 flex w-full justify-end px-4">
        <CollapsedStatusBanner handleClick={toggleCollapsed} />
      </div>
    );
  }

  if (showCustomBanner) {
    return (
      <div
        className="pointer-events-none fixed top-4 z-[1000] mt-4 flex w-full justify-center px-4"
        style={{
          left: 52 / 2, // Center the banner (ignoring the tab bar width)
        }}
      >
        <Alert
          title={showBanner.title}
          description={showBanner.description}
          severity="info"
          action={
            <>
              <span className="text-gray-600">
                Please contact{" "}
                <a href="mailto:support@hebbia.ai" className="font-medium">
                  support@hebbia.ai
                </a>
                .
              </span>
              <Button
                variant="text"
                className="absolute right-2 top-1.5 hover:bg-transparent hover:text-gray-600"
                onClick={toggleCollapsed}
              >
                <MaterialSymbolsIcon icon="close" variant="sharp" size="lg" />
              </Button>
            </>
          }
        />
      </div>
    );
  }

  return (
    <div className="pointer-events-none fixed right-0 z-[1000] mt-4 flex w-full justify-center px-4">
      <StatusBanner
        header={getBannerHeader(currentPage, bannerBuildSlow)}
        subtext={getBannerMessage(currentPage, bannerBuildSlow)}
        handleClick={toggleCollapsed}
      />
    </div>
  );
};
