import React from "react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useAutoToggleSidebar } from "source/hooks/useAutoToggleSidebar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getChatMessages, resetChatSession } from "source/redux/chat";
import { useGetRouter } from "source/hooks/useGetRouter";
import { getCurrentPage } from "source/utils/helpers";
import { TogglePerformance } from "./TogglePerformance";
import { ChatType } from "..";
import { setTargetAppliedFilters } from "source/redux/advanced";
import { ToggleSidebarButton } from "source/components/shared/TopBar/components/ToggleSidebarButton";
import { classNames } from "core";

type Props = {
  chatType?: ChatType;
};
export const ChatTopBar = ({ chatType }: Props) => {
  const dispatch = useDispatch();
  const messages = useSelector(getChatMessages);
  const { router } = useGetRouter();
  const page = getCurrentPage(router.pathname);

  // Handle auto-opening of the sidebar
  useAutoToggleSidebar(true);
  const clearChat = () => {
    // Clear applied filters if chat docs
    if (chatType === "search/chatplus") dispatch(setTargetAppliedFilters([]));
    dispatch(resetChatSession({}));
  };

  return (
    <div className="sticky left-0 top-0 z-50 w-full">
      <div className="z-10 flex h-[62px] w-full items-center gap-4 border-b border-light/50 bg-card text-sm">
        {page !== "Chat" && (
          <div className="ml-4">
            <ToggleSidebarButton />
          </div>
        )}
        <div
          className={classNames(
            "mr-2 flex flex-nowrap font-semibold text-bluePurple",
            page === "Chat" && "ml-[66px]"
          )}
        >
          <QuestionAnswerIcon style={{ fontSize: "24px" }} />
          <span className="px-2 text-base">{page}</span>
        </div>
        {messages.length > 1 ? (
          <div
            className="flex cursor-pointer select-none items-center border border-light/50 bg-card p-1.5 text-secondary hover:bg-hoverIcon active:bg-hoverIconActive"
            onClick={clearChat}
          >
            <span className="px-1">Clear chat</span>
          </div>
        ) : null}
      </div>
      {messages.length > 1 && chatType === "search/chatplus" && (
        <div className="z-10 flex flex-row items-center justify-center border-b border-light/50 bg-card">
          <div className="my-3 w-1/2">
            <TogglePerformance />
          </div>
        </div>
      )}
    </div>
  );
};
