import React from "react";
import hexToHSL from "hex-to-hsl";
import hslToHex from "hsl-to-hex";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { StyleSheet } from "source/Types";
import {
  BAAM_ORG_ID,
  PITCHBOOK_REPO_ID,
  TOP_BAR_BOTTOM_HEIGHT,
  TOP_BAR_HEIGHT,
  TOP_BAR_TOP_HEIGHT,
} from "source/constants";
import { useAutoToggleSidebar } from "source/hooks/useAutoToggleSidebar";
import { useCurrentDoc } from "source/hooks/doc-viewer/useCurrentDoc";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { useGetRouter } from "source/hooks/useGetRouter";
import { useGlobalEntitySearch } from "source/hooks/useGlobalEntitySearch";
import { useSearchHistory } from "source/hooks/search/useSearchHistory";
import { useSearchIsDisabled } from "source/hooks/search/useSearchIsDisabled";
import { useSidebarRepos } from "source/hooks/useSidebarRepos";
import { getCurrentOrg } from "source/redux/organization";
import { getSidebarVisible, setSidebarVisible } from "source/redux/sidebar";
import {
  getMetadataBarVisible,
  getReportBarVisible,
  getSearchQuery,
  getTopBarWidth,
  setTopBarWidth,
  toggleMetadataBar,
} from "source/redux/ui";
import { getUser } from "source/redux/user";
import { SearchInput } from "../SearchInput";
import { SearchDebug } from "../SearchInput/components/SearchDebug";
import { SearchMenu } from "../SearchMenu";
import { ArrowIcon } from "./components/ArrowIcon";
import { MetadataRow } from "./components/MetadataRow";
import { ToggleSidebarButton } from "./components/ToggleSidebarButton";
import { Colors } from "source/constants/colors";

const SHOW_TOPBAR = ["/repos/[id]", "/docs/[id]", "/search", "/compare"];
type Props = {
  isOnboarded?: boolean;
  searchDisabled?: boolean;
};

export const TopBar = ({ isOnboarded = true }: Props) => {
  const dispatch = useDispatch();
  const repo = useCurrentRepo(true);
  const doc = useCurrentDoc();
  const metadata = doc && doc.meta;
  const user = useSelector(getUser);
  const [_, { width: measureWidth }] = useMeasure();
  const searchDisabled = useSearchIsDisabled(repo);
  const topBarWidth = useSelector(getTopBarWidth);
  const sidebarVisible = useSelector(getSidebarVisible);
  const reportBarVisible = useSelector(getReportBarVisible);
  const metadataBarVisible = useSelector(getMetadataBarVisible);
  const currentOrg = useSelector(getCurrentOrg);
  const [h, s, l] = hexToHSL(repo?.color || Colors.blue);
  const topBarColor = hslToHex(h, 80, 98.8);
  const { history, searchSearchHistory } = useSearchHistory();
  const [filteredHistory, setFilteredHistory] = useState<string[]>([]);
  const query = useSelector(getSearchQuery);
  const { router } = useGetRouter();
  const currentRepo = useCurrentRepo(true);
  const sidebarRepos = useSidebarRepos();
  // Handle auto-opening of the sidebar
  useAutoToggleSidebar(true);

  const filterSearchRepoIds = currentRepo
    ? [currentRepo.id]
    : sidebarRepos.map((repo) => repo.id);
  const { onChange: onFilterSearchChange } = useGlobalEntitySearch({
    target: {
      ...router.query,
      doc_ids: undefined,
      repo_ids: filterSearchRepoIds,
    },
    debounce: true,
    searchStateKey: "Home",
  });

  // track actual toolbar width in redux state to avoid glitching on page refresh
  useEffect(() => {
    if (measureWidth > 0) dispatch(setTopBarWidth(measureWidth));
  }, [measureWidth]);

  const toggleMetadataBarVisible = () => dispatch(toggleMetadataBar());

  // Toggle sidebar
  useHotkeys(
    "meta+\\",
    () => {
      dispatch(setSidebarVisible(!sidebarVisible));
    },
    [sidebarVisible]
  );

  useEffect(() => {
    if (currentOrg?.id === BAAM_ORG_ID) toggleMetadataBarVisible();
  }, []);

  useEffect(() => {
    const filtered = searchSearchHistory(query, history, 5);
    setFilteredHistory(filtered);
  }, [query]);

  const shouldShowTopbar = () => {
    if (router.pathname === "/repos/[id]")
      return (
        (!!repo && repo.has_docs) ||
        repo?.integrations?.findox ||
        repo?.integrations?.pitchbook ||
        repo?.id === PITCHBOOK_REPO_ID
      );
    return SHOW_TOPBAR.includes(router.pathname);
  };

  const getBody = () => {
    if (!shouldShowTopbar())
      return (
        <div className="z-[398] w-full" style={{ height: TOP_BAR_HEIGHT }}>
          <div className="z-[700] ml-4 mt-3.5 flex flex-row items-center">
            <ToggleSidebarButton />
          </div>
        </div>
      );

    return (
      <Container
        className="border-b backdrop-blur-sm"
        style={{
          backgroundColor: topBarColor,
          paddingLeft: 16,
          height: TOP_BAR_HEIGHT,
          background: "hsla(234,35%,100%, .87)",
        }}
      >
        <div className="z-[700] mt-3.5 flex flex-row items-start">
          <ToggleSidebarButton />
        </div>
        <div className="ml-8 flex flex-grow flex-col">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: TOP_BAR_TOP_HEIGHT + 35,
            }}
            id="topbarmenu"
          >
            <div
              style={{
                position: "relative",
                width: !reportBarVisible
                  ? `calc(100% - ${topBarWidth}px + 55px)`
                  : "100%",
                maxWidth: 1200,
              }}
            >
              <SearchInput
                repo={repo}
                filteredHistory={filteredHistory}
                onSearchChange={onFilterSearchChange}
                searchDisabled={searchDisabled}
              />
            </div>
          </div>
          <SearchDebug />
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              height: TOP_BAR_BOTTOM_HEIGHT,
              alignItems: "center",
            }}
          >
            <div
              className="mb-0.5 flex max-w-[1200px]"
              style={{
                width: !reportBarVisible
                  ? `calc(100% - ${topBarWidth}px + 80px)`
                  : "100%",
              }}
            >
              <SearchMenu repo={repo} />
              {!!metadata && !metadataBarVisible && (
                <ArrowIcon
                  rowVisible={false}
                  handleClick={toggleMetadataBarVisible}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    );
  };

  return (
    <>
      {getBody()}
      {!!metadata &&
        metadataBarVisible &&
        (currentOrg?.id === BAAM_ORG_ID || user?.platform_role === "admin") && (
          <MetadataRow
            doc={doc}
            backgroundColor={topBarColor}
            handleClick={toggleMetadataBarVisible}
          />
        )}
    </>
  );
};

const Container = styled.div`
  /* same as TriadBack */
  @media only screen and (max-width: 600px) {
    padding: 0px 20px;
  }
  position: sticky;
  top: 0px;
  display: flex;
  width: 100%;
  z-index: 300;
  -webkit-user-select: none;
  -webkit-app-region: drag;
`;

export const styles: StyleSheet = {
  lastBuiltLabel: {
    color: "rgb(140,140,140)",
    fontSize: 12.5,
    marginTop: 8,
    fontWeight: 400,
  },
  lastBuiltFailedLabel: {
    color: "red",
    fontSize: 12.5,
    marginTop: 8,
    fontWeight: 400,
  },
  menuButton: {
    color: "var(--text-secondary)",
    marginLeft: 20,
    transform: "scale(-1, 1)",
    cursor: "pointer",
  },
};
