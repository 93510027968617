import { SidebarRow } from "./SidebarRow";
import React from "react";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { RepoType } from "source/Types";
import { useHotkeys } from "react-hotkeys-hook";
import { LoadingRow } from "./LoadingRow";
import { useCreateFoldersMutation } from "source/api/docs/useMutateDocs";
import { useSelector } from "react-redux";
import { getCurrentOrg } from "source/redux/organization";
import { getDisableLocalUpload } from "source/utils/integrations/status";

type Props = {
  title?: string;
  repo: RepoType;
};

export const AddFolderSidebarRow = ({
  title = "Create Folder",
  repo,
}: Props) => {
  const currentOrg = useSelector(getCurrentOrg);
  const disablePersonalUploads = getDisableLocalUpload(currentOrg);

  const createFolderMutation = useCreateFoldersMutation();

  const handleAddFolder = async () => {
    // don't allow adding folders in findox
    if (repo.integrations?.findox) return;
    // Only allow folder creation on private repos
    if (repo.private) createFolderMutation.mutate({ repoId: repo.id });
  };

  useHotkeys(
    "meta+M",
    (e) => {
      e.preventDefault();
      handleAddFolder();
    },
    []
  );

  if (disablePersonalUploads) return null;

  if (createFolderMutation.isLoading) return <LoadingRow depth={0} />;

  return (
    <SidebarRow
      id="add"
      title={title}
      style={{
        width: "100%",
        color: "var(--text-tertiary)",
        WebkitTextFillColor: "var(--text-tertiary)",
        padding: "8px",
      }}
      icon={
        <span className="ml-1 flex h-5 w-5 cursor-pointer select-none items-center justify-center text-center text-lg text-secondary hover:bg-hoverIcon/30 active:bg-hoverIconActive/30">
          <AddSharpIcon fontSize="small" />
        </span>
      }
      onClick={handleAddFolder}
    />
  );
};
